import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/hegel/hegel/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "class-types"
    }}>{`Class Types`}</h1>
    <hr></hr>
    <p>{`The same as in other "analyzers" Hegel doesn't provide a special syntax for class (only type syntax for properties and methods). You can define class the same as in JavaScript, but Hegel will create a type for this class and it's constructor.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`class User {
  name: string;
  password: string;

  constructor(name: string, password: string): User {
    this.name = name;
    this.password = password;
  }
}

const user: User = new User("any.user@gmail.com", "qwerty");

// Type is "class User"
const UserConstructor = User;
`}</code></pre>
    <h2 {...{
      "id": "class-methods"
    }}>{`Class Methods`}</h2>
    <p>{`As with `}<a parentName="p" {...{
        "href": "/docs/function-types"
      }}>{`Function Types`}</a>{`, Hegel provides syntax for argument types and return type;`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`class Fighter {
  tellTheRules(isNewbee: boolean): string {
    return isNewbee
      ? "I don't know the rules."
      : \`
      1. You do not talk about Fight Club.
      2. You DO NOT talk about Fight Club.
      3. If someone yells “stop!”, goes limp, or taps out, the fight is over.
      4. Only two guys to a fight.
      5. One fight at a time, fellas.
      6. The fights are bare knuckle. No shirt, no shoes, no weapons.
      7. Fights will go on as long as they have to.
      8. If this is your first time at Fight Club, you have to fight.\`;
  }
}
`}</code></pre>
    <h2 {...{
      "id": "class-fields"
    }}>{`Class Fields`}</h2>
    <p>{`If you want to use a class field you must define it first and give it an annotation.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`class Fighter {
  tellTheRules(): string {
    // Error: Property "isNewbie" does not exist in "Fighter"
    return this.isNewbie
      ? "I don't know the rules."
      : \`
      1. You do not talk about Fight Club.
      2. You DO NOT talk about Fight Club.
      3. If someone yells “stop!”, goes limp, or taps out, the fight is over.
      4. Only two guys to a fight.
      5. One fight at a time, fellas.
      6. The fights are bare knuckle. No shirt, no shoes, no weapons.
      7. Fights will go on as long as they have to.
      8. If this is your first time at Fight Club, you have to fight.\`;
  }
}
`}</code></pre>
    <p>{`You can define class fields inside the body of class.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`class Fighter {
  isNewbie: boolean = true;

  tellTheRules(): string {
    return this.isNewbie
      ? "I don't know the rules."
      : \`
      1. You do not talk about Fight Club.
      2. You DO NOT talk about Fight Club.
      3. If someone yells “stop!”, goes limp, or taps out, the fight is over.
      4. Only two guys to a fight.
      5. One fight at a time, fellas.
      6. The fights are bare knuckle. No shirt, no shoes, no weapons.
      7. Fights will go on as long as they have to.
      8. If this is your first time at Fight Club, you have to fight.\`;
  }
}
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`If you are already familiar with TypeScript, Hegel does not provide accessibility modifiers like `}<inlineCode parentName="p">{`public`}</inlineCode>{`, `}<inlineCode parentName="p">{`private`}</inlineCode>{` or `}<inlineCode parentName="p">{`protected`}</inlineCode>{`.`}</p>
    </blockquote>
    <h2 {...{
      "id": "static-members"
    }}>{`Static Members`}</h2>
    <p>{`The same as in JavaScript, static members of class will be inside the class constructor and not inside the class instance.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`class FightClub {
  static tellTheRules(): string {
    return \`
      1. You do not talk about Fight Club.
      2. You DO NOT talk about Fight Club.
      3. If someone yells “stop!”, goes limp, or taps out, the fight is over.
      4. Only two guys to a fight.
      5. One fight at a time, fellas.
      6. The fights are bare knuckle. No shirt, no shoes, no weapons.
      7. Fights will go on as long as they have to.
      8. If this is your first time at Fight Club, you have to fight.\`;
  }
}

const fightClubRules = FightClub.tellTheRules(); // 👌!

const fightClubInNYC = new FightClub();

// Error: Property "tellTheRules" does not exist in "FightClub"
const localFightClubRules = fightClubInNYC.tellTheRules();
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      